<template>
  <v-card elevation="1">
    <v-toolbar flat>
      <v-toolbar-title class="page-title">
        <v-icon class="secondary--text" left light> {{ icons.mdiPlus }}</v-icon>
        {{ $route.meta.title }}</v-toolbar-title
      >
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-btn color="accent" class="mx-1" rounded @click.prevent="saveEstimate">
        <v-icon class="mr-1" right light> {{ icons.mdiContentSave }}</v-icon>
        <span>Save</span>
      </v-btn>
      <v-btn color="secondary" rounded @click.prevent="$router.push({ name: 'workflow' })">
        <v-icon class="mr-1" right light>{{ icons.mdiClose }}</v-icon>
        <span>Cancel</span>
      </v-btn>
    </v-toolbar>
    <v-divider class="mb-2"></v-divider>
    <v-container>
      <v-row>
        <v-col cols="12" md="12">
          <v-layout>
            <span class="mr-5 ma-1">Estimate ({{ estimate.estimate_number }})</span>
            <v-text-field
              v-model="estimate.title"
              :prepend-inner-icon="icons.mdiCardAccountDetails"
              color="secondary"
              label="Title"
              dense
              outlined
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-menu offset-y bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn light icon v-bind="attrs" v-on="on">
                  <v-icon> {{ icons.mdiDotsVertical }}</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click.prevent="$router.push({ name: 'addnewcustomer' })">
                  <v-list-item-icon>
                    <v-icon size="20" color="primary" class="mx-1">{{ icons.mdiAccountTie }}</v-icon>
                    <v-list-item-title>Show Customer</v-list-item-title>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item @click.prevent="$router.push({ name: 'addnewvehicle' })">
                  <v-list-item-icon>
                    <v-icon size="20" color="primary" class="mx-1">{{ icons.mdiAccountTie }}</v-icon>
                    <v-list-item-title>Show Vehicle</v-list-item-title>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item @click.prevent="">
                  <v-list-item-icon>
                    <v-icon size="20" color="primary" class="mx-1">{{ icons.mdiPencil }}</v-icon>
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item @click.prevent="">
                  <v-list-item-icon>
                    <v-icon size="20" color="error" class="mx-1">{{ icons.mdiClose }}</v-icon>
                    <v-list-item-title>Remove</v-list-item-title>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-layout>
        </v-col>
        <v-col cols="6" md="6">
          <div>
            <v-autocomplete
              v-model="estimate.customer_id"
              :items="customers"
              item-value="customer_id"
              item-text="customer_name"
              :prepend-inner-icon="icons.mdiAccountTie"
              outlined
              color="secondary"
              dense
              chips
              deletable-chips
              small-chips
              label="Customer Name"
              single-line
            ></v-autocomplete>

            <v-card elevation="1" outlined class="text-center mt-n5">
              <v-avatar class="my-2" color="secondary" size="70">
                <v-icon size="40" color="accent">{{ icons.mdiAccountTie }}</v-icon>
              </v-avatar>
              <v-card-subtitle class="pb-0" style="font-weight: bold">
                {{ estimate.customer_name }}
              </v-card-subtitle>

              <v-card-text class="text--primary">
                <p class="small text-muted mb-0">
                  <v-icon class="mr-1"> {{ icons.mdiPhone }}</v-icon
                  >{{ estimate.tel_number }}
                </p>
                <p class="small text-muted mb-0">
                  <v-icon class="mr-1"> {{ icons.mdiEmailOutline }}</v-icon
                  >{{ estimate.email }}
                </p>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click.prevent="$router.push({ name: 'addnewcustomer' })" rounded color="primary">
                  <v-icon left>{{ icons.mdiPlus }}</v-icon>
                  Add New
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
        <v-col cols="6" md="6">
          <v-autocomplete
            v-model="estimate.vehicle_id"
            :items="vehicles"
            item-value="vehicle_id"
            item-text="vehicle_name"
            :prepend-inner-icon="icons.mdiCar"
            outlined
            color="secondary"
            dense
            chips
            deletable-chips
            small-chips
            label="Vehicle Name"
            single-line
          ></v-autocomplete>
          <v-card elevation="1" outlined class="text-center mt-n5">
            <v-avatar class="my-2" color="secondary" size="70">
              <v-icon size="40" color="accent">{{ icons.mdiCar }}</v-icon>
            </v-avatar>
            <v-card-subtitle class="pb-0" style="font-weight: bold">{{ estimate.vehicle_name }}</v-card-subtitle>
            <v-card-text class="text--primary">
              <p class="small text-muted mb-0">
                <v-icon class="mr-1">{{ icons.mdiNumeric7BoxMultipleOutline }}</v-icon
                >{{ estimate.license_plate }}
              </p>
              <p class="small text-muted mb-0">1.5 hours billed</p>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click.prevent="$router.push({ name: 'addnewvehicle' })" rounded color="primary">
                <v-icon left>{{ icons.mdiPlus }}</v-icon>
                Add New
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" sm="6">
          <v-dialog
            ref="duedate_menu"
            v-model="duedate_menu"
            :return-value.sync="estimate.due_date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="estimate.due_date"
                label="Due Date"
                color="secondary"
                :prepend-inner-icon="icons.mdiCalendarAccount"
                outlined
                dense
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="estimate.due_date" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="duedate_menu = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.duedate_menu.save(estimate.due_date)"> OK </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="12" md="6" sm="6">
          <v-autocomplete
            v-model="estimate.service_writer"
            :items="users"
            item-value="user_id"
            item-text="username"
            :prepend-inner-icon="icons.mdiAccountTie"
            outlined
            color="secondary"
            dense
            chips
            deletable-chips
            small-chips
            label="Service Writer"
            single-line
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-card elevation="2" class="my-5">
        <v-toolbar height="45" flat color="primary" light>
          <v-toolbar-title class="white--text">Services and Inspections</v-toolbar-title>
        </v-toolbar>
        <v-tabs horizontal>
          <v-tab>
            <v-icon left>{{ icons.mdiCarCog }}</v-icon>
            Services
          </v-tab>
          <v-tab>
            <v-icon left> {{ icons.mdiClipboardCheck }}</v-icon>
            Inspections
          </v-tab>
          <v-tab>
            <v-icon left>{{ icons.mdiAlarm }}</v-icon>
            Time Clocks
          </v-tab>
          <v-tab>
            <v-icon left>{{ icons.mdiFormatListText }}</v-icon>
            Summary
          </v-tab>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-textarea
                      background-color="transparent"
                      color="secondary"
                      dense
                      label="Customer Comments"
                      outlined
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-textarea
                      background-color="transparent"
                      color="secondary"
                      dense
                      label="Recommendations"
                      outlined
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <Services :estimate_id="this.estimate.estimate_id" />
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-divider></v-divider>
              <Inspections :estimate_id="this.estimate.estimate_id" />
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <p>Summary</p>

                <p class="mb-0">Clock</p>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-container>
  </v-card>
</template>

<script>
import mitt from 'mitt'
window.mitt = window.mitt || new mitt()
import Services from '@/components/workflow/services/Services.vue'
import Inspections from '@/components/workflow/inspections/Inspections.vue'
import {
  mdiCalculatorVariant,
  mdiCardAccountDetails,
  mdiPlus,
  mdiContentSave,
  mdiClose,
  mdiDotsVertical,
  mdiAccountTie,
  mdiCar,
  mdiEmailOutline,
  mdiPencil,
  mdiPhoneOutline,
  mdiNumeric7BoxMultipleOutline,
  mdiFormatListText,
  mdiCarCog,
  mdiClipboardCheck,
  mdiAlarm,
} from '@mdi/js'
import { debounce } from 'lodash'
import { mapGetters } from 'vuex'
export default {
  components: {
    Services,
    Inspections,
  },
  created: function () {
    this.getEstimates()
    this.selectCustomers()
    this.selectVehicles()
    this.selectUsers()
  },
  data() {
    return {
      duedate_menu: false,
      activeTabs: 'Services',
      estimates: [],
      customers: [],
      vehicles: [],
      users: [],
      estimate: {
        worflow_id: 1,
        service_writer: '',
      },
      filterCustomer: [],
      filterVehicle: [],
      icons: {
        mdiCalculatorVariant,
        mdiCardAccountDetails,
        mdiDotsVertical,
        mdiPlus,
        mdiContentSave,
        mdiClose,
        mdiAccountTie,
        mdiCar,
        mdiEmailOutline,
        mdiPhoneOutline,
        mdiNumeric7BoxMultipleOutline,
        mdiFormatListText,
        mdiCarCog,
        mdiClipboardCheck,
        mdiAlarm,
        mdiPencil,
      },
    }
  },
  methods: {
    getEstimates: function () {
      this.$store
        .dispatch('estimate/fetchNewEstimate')
        .then(response => {
          this.estimate = this.fetchEstimates
          localStorage.storedData = this.estimate.estimate_id
        })
        .catch(err => {
          console.log(err)
        })
    },
    saveEstimate: debounce(function () {
      this.$store
        .dispatch(`estimate/updateNewEstimate`, {
          estimate_id: this.estimate.estimate_id,
          data: this.estimate,
        })
        .then(response => {
          const Toast = this.$swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          })

          Toast.fire({
            icon: 'success',
            title: 'Record added successfully',
          })
          window.mitt.emit('saveServices')
          this.getEstimates()
          this.$router.push({ name: 'workflow' })
        })

        .catch(error => {
          this.$swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Unable to save the transaction, Please try again!',
            confirmButtonColor: '#3085d6',
          })
          console.log(error)
        })
    }, 800),

    selectCustomers: debounce(function () {
      this.$store
        .dispatch('estimate/selectCustomer')
        .then(response => {
          ;(this.customers = this.selectCustomer), response
        })
        .catch(err => {
          console.log(err)
        })
    }, 800),

    selectVehicles: debounce(function () {
      this.$store
        .dispatch('estimate/selectVehicle')
        .then(response => {
          ;(this.vehicles = this.selectVehicle), response
        })
        .catch(err => {
          console.log(err)
        })
    }, 800),

    selectUsers: function () {
      this.$store
        .dispatch('appointment/selectUsername')
        .then(response => {
          this.users = this.selectUser
        })
        .catch(err => {
          console.log(err)
        })
    },

    filteredCustomers: function (event) {
      //this.filterCustomer = this.customers.find(customer => customer.customer_id === event.value)
      console.log(event.value)
    },
    filteredVehicles: function (event) {
      this.filterVehicle = this.vehicles.find(vehicle => vehicle.vehicle_id === event.value)
    },
  },

  computed: {
    ...mapGetters({
      fetchEstimates: 'estimate/fetchEstimates',
      selectCustomer: 'estimate/selectCustomer',
      selectVehicle: 'estimate/selectVehicle',
      estimateNumber: 'estimate/estimateNumber',
      selectUser: 'appointment/fetchUsers',
    }),
    estimate_id() {
      return this.estimate.estimate_id
    },
  },

  watch: {
    estimate_id: function () {
      localStorage.storedData
    },
  },
}
</script>

<style>
</style>